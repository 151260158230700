import general from './general.yaml';
// import shared from './shared.yaml';
// import about from './about.yaml';
// import category0 from './category0.yaml';
// import category1 from './category1.yaml';
// import category2 from './category2.yaml';
// import category3 from './category3.yaml';
// import category4 from './category4.yaml';
// import category5 from './category5.yaml';
// import category6 from './category6.yaml';
// import category7 from './category7.yaml';
import makeI18n from '../../makeI18n';

export default makeI18n([
  general,
  // shared,
  // about,
  // category0,
  // category1,
  // category2,
  // category3,
  // category4,
  // category5,
  // category6,
  // category7,
]);
